import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Realtor, RealtorsService, GlobalProgressService, completedOrFailed } from '../../core';

/**
 * Realtor resolver.
 */
@Injectable()
export class RealtorResolver implements Resolve<Realtor> {

  constructor(
    private realtorsService: RealtorsService,
    private globalProgressService: GlobalProgressService,
  ) { }

  /**
   * Gets id from params and fetches info about realtor.
   * @param route Route snapshot.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Realtor> {
    const id = Number(route.paramMap.get('id'));
    this.globalProgressService.startLoading();
    return this.realtorsService
      .getById(id)
      .pipe(
        completedOrFailed(() => this.globalProgressService.stopLoading()),
      );
  }

}
