import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Realtor, RealtorsService, GlobalProgressService, completedOrFailed } from '../../core';

/**
 * Realtor profile resolver.
 */
@Injectable()
export class RealtorProfileResolver implements Resolve<Realtor> {

  constructor(
    private realtorService: RealtorsService,
    private globalProgressService: GlobalProgressService,
  ) { }

  /**
   * Fetches realtor's profile info.
   */
  public resolve(): Observable<Realtor> {
    this.globalProgressService.startLoading();
    return this.realtorService
      .getProfile()
      .pipe(
        completedOrFailed(() => this.globalProgressService.stopLoading()),
      );
  }

}
